import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FileReaderInput from 'react-file-reader-input';
import * as process from '../environment/env.production';

var firmafiel = require('@cesarvm/firmafiel-siippg');
let privayeKeyPem = '';
let publicKeyPem = '';

const FirmaForm = ({ onSignComplete, onSignError, idUsuario, idCarga, procedencia, nombre, idCargaS, tipoPersona }) => {
  console.log(idCargaS);
  const [password, setPassword] = useState('');
  const [privateKey, setPrivateKey] = useState(false);
  const [publicKey, setPublicKey] = useState(false);
  const [publicKeyValid, setPublicKeyValid] = useState(false);
  const [privateKeyValid, setPrivateKeyValid] = useState(false);
  const [validandoPublicKey, setValidandoPublicKey] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [statusComplete, setStatusComplete] = useState(false);
  const [errorCert, setErrorCert] = useState(false);
  const [rfcValid, setRfcValid] = useState(false);
  const [rfcForm, setRfc] = useState('');
  const privateKeyRef = useRef(null);
  const publicKeyRef = useRef(null);

  const validatePrivateKey = (file) => {
    privayeKeyPem = firmafiel.keyBufferToPem({
      derBuffer: file,
    });
    setPrivateKeyValid(
      privayeKeyPem.includes('-----END ENCRYPTED PRIVATE KEY-----')
        ? true
        : false
    );
  };

  const validatePublicKey = (file) => {
    publicKeyPem = firmafiel.certBufferToPem({ derBuffer: file });
    firmafiel
      .verificarCertificado({
        certificado: publicKeyPem,
      })
      .then((result) => {
        setPublicKeyValid((result.data.status === 'good' || result.status === 200) ? true : false);
        setRfcValid(
          firmafiel.validaRfcFromPem({
            pem: publicKeyPem,
            rfc: rfcForm,
          })
        );
        setValidandoPublicKey(false);
      });
  };

  const handlePrivateKey = (event, results) => {
    results.forEach((result) => {
      const [e, file] = result;
      if (
        file.type === 'application/x-iwork-keynote-sffkey' ||
        file.type === ''
      ) {
        setPrivateKey(true);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
          validatePrivateKey(reader.result);
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
      } else {
        console.error('Debe elegir un archivo .key válido(llave privada)');
      }
    });
  };

  const handlePublicKey = (event, results) => {
    setValidandoPublicKey(true);
    results.forEach((result) => {
      const [e, file] = result;
      if (
        file.type === 'application/pkix-cert' ||
        file.type === 'application/x-x509-ca-cert' ||
        file.type === ''
      ) {
        setPublicKey(true);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
          setErrorCert(false);
          validatePublicKey(reader.result);
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
      } else {
        console.error('Debe elegir un archivo .cer válido(llave pública)');
        setErrorCert(true);
        setValidandoPublicKey(false);
      }
    });
  };

  const signComplete = ({ status, firmapem }) => {
    if (status === 'ok'){
      onSignComplete({ status: 'completed', firma: firmapem });
      setPasswordInvalid(false);
      setStatusComplete(true);
      console.log(process.env.REACT_SERVER_SIIPPG)
      
      if(procedencia === 'integracion'){
        window.location.href =`${process.env.REACT_SERVER_SIIPPG}SIIPPG/recepcion?id_transaccion=42988698`;

      } else if(procedencia === 'carga'){
        window.location.href =`${process.env.REACT_SERVER_SIIPPG}SIIPPG/recepcion?id_transaccion=42993040&parametros=modulo%AC${procedencia}~usuario%AC${idUsuario}~carga%AC${idCarga}~tipoPersona%AC${tipoPersona}`;

      } else if(procedencia === 'justificacion'){
        window.location.href =`${process.env.REACT_SERVER_SIIPPG}SIIPPG/recepcion?id_transaccion=42993152&parametros=modulo%AC${procedencia}~usuario%AC${idUsuario}~nombre%AC${nombre}`;

      } else if(procedencia === 'desbloqueo' || procedencia === 'recuperar'){
        window.location.href =`${process.env.REACT_SERVER_SIIPPG}SIIPPG/recepcion?id_transaccion=42994794&parametros=modulo%AC${procedencia}~usuario%AC${idUsuario}`;

      }
    } else {
      onSignError({ error: 'Error: Wrong password probably' });
      setPasswordInvalid(true);
    }
  };

  const sign = () => {
    if (privateKeyValid && publicKeyValid && rfcValid) {
      const firma = firmafiel.firmarCadena({
        pempublica: publicKeyPem,
        pemprivada: privayeKeyPem,
        passprivada: password,
      });
      signComplete(firma);
    }
  };

  return (
    <div>
      <div>
       <label htmlFor='rfc'>RFC:</label>
       <br></br>
       <input
        id='rfc'
        type='text'
        value={rfcForm}
        onChange={(e) => setRfc(e.target.value)}
        ></input>
        <br></br>
        <label htmlFor='privateKeyInput'>Llave Privada (.key):</label>
        <FileReaderInput
          id='privateKeyInput'
          as='buffer'
          ref={privateKeyRef}
          onChange={handlePrivateKey}
        >
          <button>Selecciona un archivo...</button>
        </FileReaderInput>
        {privateKey && !privateKeyValid && <p style={{ color: 'red', fontWeight: 'bold' }}>Llave privada no válida</p>}
        {privateKey && privateKeyValid && <p style={{ color: 'green', fontWeight: 'bold' }}>Llave privada válida</p>}
      </div>
      <div>
        <label htmlFor='publicKeyInput'>Llave Publica (.cer):</label>
        <FileReaderInput
          id='publicKeyInput'
          as='buffer'
          ref={publicKeyRef}
          onChange={handlePublicKey}
        >
          <button>Selecciona un archivo...</button>
        </FileReaderInput>
        {validandoPublicKey && (
          <p style={{ color: 'gray', fontWeight: 'bold' }}>...validando llave pública con servicio OCSP</p>
        )}
        {!validandoPublicKey && publicKey && !publicKeyValid && (
          <p style={{ color: 'red', fontWeight: 'bold' }}>Llave pública no válida</p>
        )}
        {!validandoPublicKey && publicKey && publicKeyValid && (
          <p style={{ color: 'green', fontWeight: 'bold' }}>Llave pública válida</p>
        )}
        {errorCert && (
          <p style={{ color: 'red', fontWeight: 'bold' }}>Debe elegir un archivo .cer válido</p>
        )}
      </div>
      {publicKeyValid && !rfcValid && (
        <p style={{ color: 'red', fontWeight: 'bold' }}>El RFC proporcionado no concuerda con la llave pública (*.cer)</p>
      )}
      <label htmlFor='password'>Contraseña de Llave Privada (.key):</label>
      <br></br>
      <input
        id='password'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      ></input>
      {passwordInvalid && (
        <p style={{ color: 'red', fontWeight: 'bold' }}>El password es nulo o incorrecto</p>
      )}
      {statusComplete && (
        <p style={{ color: 'gray', fontWeight: 'bold' }}>Redireccionando ...</p>
      )}
      <br></br>
      <br></br>
      {!validandoPublicKey && (
        <button onClick={sign}>Firmar</button>
      )}
    </div>
  );
};

FirmaForm.propTypes = {
  onSignComplete: PropTypes.func,
  onSignError: PropTypes.func,
};

export default FirmaForm;

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./App.css";
import FirmaElectronica from "./components/FirmaElectronica";
import FirmaElectronicaJustificacion from "./components/FirmaElectronicaJustificacion";
import FirmaElectronicaCarga from "./components/FirmaElectronicaCarga";
import FirmaElectronicaUsuario from "./components/FirmaElectronicaUsuario";

const App = () => {
  const [firma, setFirma] = useState();

  const onSignComplete = ({ status, firma }) => {
    setFirma(firma);
  };
  const onSignError = ({ error }) => {
    console.error(error);
  };
  
  return (
    <Router>
      <Switch>
        <Route exact path="/usuario/:modulo/:idUsuario" render={() => <FirmaElectronicaUsuario onSignComplete={onSignComplete} onSignError={onSignError}/>} />
        <Route exact path="/justificacion/:idCarga/:idUsuarioCarga/:nombreArchivo" render={() => <FirmaElectronicaJustificacion onSignComplete={onSignComplete} onSignError={onSignError}/>} />
        <Route exact path="/pencarga/:idCarga/:idUsuarioCarga/:idCargas/:idTipoPersona/:tipo" render={() => <FirmaElectronicaCarga onSignComplete={onSignComplete} onSignError={onSignError}/>} />
        <Route exact path="/cargaPadrones" render={() => <FirmaElectronica onSignComplete={onSignComplete} onSignError={onSignError}/>} />
      </Switch>
    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FirmaForm from './FirmaForm';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

Modal.setAppElement('#root');

const FirmaElectronica = ({ onSignComplete, onSignError }) => {
  const modulo = 'integracion';
  let subtitle;

  const [modalIsOpen, setIsOpen] = useState(true);

  const onSignCompleted = ({ status, firma }) => {
    if (status === 'completed') {
      onSignComplete({ status, firma });
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel='Modal Firma'
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}>Firma Electrónica</h2>
        <FirmaForm
          onSignComplete={onSignCompleted}
          onSignError={onSignError}
          idUsuario={null}
          idCarga={null}
          procedencia={modulo}
          nombre={null}
          idCargaS={null}
          tipoPersona={null}
        />
      </Modal>
    </div>
  );
};

FirmaElectronica.propTypes = {
  onSignComplete: PropTypes.func,
  onSignCompleted: PropTypes.func
};

export default FirmaElectronica;

const getEnvironmentVariable = (value) => {
  let data = {};
  const ambiente = window.location.hostname;

  switch (ambiente) {
    case 'dgti-eaf-sfpfirmae-staging.apps.funcionpublica.gob.mx':
      data = {
        //REACT_SERVER_SIIPPG: 'http://172.16.250.64/',
        REACT_SERVER_SIIPPG: 'http://172.29.50.81:8080/',
      };
      break;

    case 'dgti-eaf-sfpfirmae.apps.funcionpublica.gob.mx':
      data = {
        //REACT_SERVER_SIIPPG: 'http://172.29.50.81:8080/',
        REACT_SERVER_SIIPPG: 'https://siippghistorico.funcionpublica.gob.mx/',
      };
      break;

    default:
      data = {
        REACT_SERVER_SIIPPG: 'http://172.29.50.81:8080/',
      };
  }

  return data[value];
};

export const env = {
  REACT_SERVER_SIIPPG: getEnvironmentVariable('REACT_SERVER_SIIPPG'),
};
